import { spacing } from "../../common/styles/Spacing";
import { colors } from "../../common/styles/Colors";
import { truncate, defaultBorder } from "../../common/styles/Globals";
import { fontWeight } from "../../common/styles/Typography";
import bgCover from "../../common/images/default-avatar.png";

export const auth = {
    body: {
        maxWidth: 320,
        margin: "0 auto",
    },
    logo: {
        marginBottom: spacing.space4,
        textAlign: "center",
    },
    textMainBig: {
        color: "#2A6157",
        fontSize: 24,
        fontWeight: fontWeight.black,
        letterSpacing: 0.23,
        lineHeight: 1.33,
        marginBottom: spacing.space2,
    },
    textMain: {
        color: "#2A6157",
        fontSize: 15,
        letterSpacing: 0.23,
        lineHeight: 1.33,
        marginTop: spacing.space2,
    },
    textMainAccount: {
        color: "#2A6157",
        fontSize: 15,
        letterSpacing: 0.23,
        lineHeight: 1.33,
        marginTop: spacing.space2,
        marginBottom: spacing.space3,
    },
    avatar: {
        display: "flex",
        justifyContent: "center",
        marginBottom: spacing.space13,
        "@media (max-width: 1023px)": {
            marginBottom: 0,
        },
    },
    helpMore: {
        position: "fixed",
        top: spacing.space4,
        right: spacing.space4,
    },
    fieldError: {
        marginTop: -spacing.space2,
        marginBottom: spacing.space3,
    },
    content: {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space6,
        "@media (max-width: 1023px)": {
            paddingBottom: spacing.space3,
            paddingRight: spacing.space2,
            paddingLeft: spacing.space2,
        },
    },
    actionLink: {
        "@media (max-width: 1023px)": {
            color: "#8f8f8f",
        },
    },
    actionLinkBold: {
        "@media (max-width: 1023px)": {
            color: "#00565F",
        },
    },
};

export const authSlider = {
    slide: {
        borderRadius: spacing.space0,
        backgroundColor: colors.black10,
        paddingTop: "100%",
        outline: "none",
    },
};

export const terms = {
    list: {
        marginTop: spacing.space3,
        borderTop: "1px solid rgba(0,0,0,0.1)",
    },
};

export const channelsList = {
    body: {
        marginTop: spacing.space2,
    },
    item: {
        display: "flex",
        borderBottom: defaultBorder,
        paddingTop: 6,
        paddingRight: 16,
        paddingBottom: 6,
        paddingLeft: 16,
    },
    title: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        paddingRight: spacing.space1,
        minWidth: 0,
    },
    titleInner: {
        fontSize: 17,
        fontWeight: fontWeight.normal,
        lineHeight: 1.29,
        ...truncate,
    },
    switch: {
        width: 51,
    },
};

export const imageForm = {
    container: {
        justifyContent: "flex-start",
    },
    field: {
        flexDirection: "row",
        alignItems: "center",
    },
    photoUpload: {
        display: "block",
        cursor: "pointer",
        border: "0 none",
        outline: "0 none",
        borderRadius: 80,
        padding: 0,
        width: 160,
        height: 160,
        margin: "0 auto",
        position: "relative",
    },
    photoBgUpload: {
        display: "block",
        cursor: "pointer",
        border: "0 none",
        outline: "0 none",
        padding: 0,
        width: "100%",
        position: "relative",
    },
    photoBgUploadImg: {
        width: "100%",
        height: 250,
        // height: 236,
        // paddingTop: "44.64%",
        backgroundColor: "transparent",
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${bgCover})`,
    },
    userPhoto: {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: 160,
        height: 160,
        borderRadius: 80,
    },
    icoEdit: {
        display: "flex",
        width: 36,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 18,
        backgroundColor: colors.white,
        borderColor: colors.black10,
        borderWidth: 1,
        borderStyle: "solid",
        position: "absolute",
        right: spacing.space1,
        bottom: spacing.space1,
        zIndex: 100,
    },
    icoBgEdit: {
        display: "flex",
        width: 36,
        height: 36,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 18,
        backgroundColor: colors.white,
        borderColor: colors.black10,
        borderWidth: 1,
        borderStyle: "solid",
        position: "absolute",
        right: -spacing.space1,
        bottom: spacing.space0,
        zIndex: 100,
    },
    error: {
        marginBottom: spacing.space4,
        textAlign: "center",
        marginTop: -spacing.space5,
    },
};

export const resetPassModal = {
    accordionList: {
        borderTop: `1px solid ${colors.black12}`,
        marginTop: spacing.space2comma5,
        marginBottom: spacing.space3,
    },
    hint: {
        color: colors.black50,
        fontSize: 12,
        lineHeight: 1.66,
        letterSpacing: 0.35,
    },
    link: {
        color: colors.accentA,
        fontFamily: "inherit",
        fontSize: "inherit",
        textDecoration: "underline",
        outline: 0,
        border: 0,
        background: "none",
        margin: 0,
        padding: 0,
    },
    list: {
        paddingLeft: spacing.space2,
        marginTop: spacing.space0comma5,
    },
    item: {
        paddingLeft: spacing.space1,
        marginTop: spacing.space0comma5,
    },
};

export const accordionItem = {
    root: {
        display: "flex",
        flexDirection: "column",
        borderBottom: `1px solid ${colors.black12}`,
    },
    header: {
        display: "flex",
        paddingTop: spacing.space2comma5,
        paddingBottom: spacing.space2comma5,
        cursor: "pointer",
    },
    icon: {
        width: 20,
        height: 20,
        paddingTop: spacing.space1comma5,
        "& svg": {
            transition: "transform 0.2s ease",
        },
    },
    title: {
        paddingLeft: spacing.space2,
        "& h4": {
            fontSize: 16,
            margin: 0,
        },
        "& h5": {
            fontSize: 12,
            margin: 0,
        },
    },
    collapse: {
        position: "relative",
        height: 0,
        overflow: "hidden",
        transition: "height 0.35s ease",
    },
    collapseActive: {
        height: "auto",
    },
    content: {
        paddingBottom: spacing.space2comma5,
    },
    action: {
        paddingTop: spacing.space5,
    },
};
