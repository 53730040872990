/** @jsx jsx */
import { jsx } from "@emotion/core";
import ModalBox, { ModalBoxText } from "../../common/components/UI/ModalBox";
import { useRef, useState } from "react";
import { accordionItem, resetPassModal } from "./AuthStyles";
import ButtonShape from "../../common/components/UI/ButtonShape";
import Icon from "../../common/components/UI/Icon";
import { colors } from "../../common/styles/Colors";
import authActionsCreators from "../../store/Auth/actionCreators";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";

const items = [
    {
        id: 1,
        title: "Your LSE network account",
        subtitle: "for current students",
        content:
            "<p>Your username will be no more than 8 characters, starting with your surname, e.g. DOEJ2 or DOSTOYEV.</p><p>Your password is the same that you use for LSE email (Microsoft). If you forget it, you can reset your LSE password.</p>",
        buttonText: "Reset LSE Password",
        buttonLink: "https://info.lse.ac.uk/staff/divisions/dts/Password/Forgotten-your-password",
    },
    {
        id: 2,
        title: "Your LSE Public Account",
        subtitle: "for offer holders and current students",
        content:
            "<p>Your username is your personal email address that you used to apply to LSE.</p><p>Your password is what you set up when you applied to LSE. If you forget it, you can reset your Public Account password.</p>",
        buttonText: "Reset Public Account Password",
        buttonLink: "https://apps.lse.ac.uk/publicaccounts/resetPassword.html",
    },
];

const AccordionItem = props => {
    const contentEl = useRef();
    const { handleToggle, active, item } = props;
    const { id, title, subtitle, content, buttonLink, buttonText } = item;

    return (
        <div css={accordionItem.root}>
            <div css={accordionItem.header} onClick={() => handleToggle(id)}>
                <div css={accordionItem.icon}>
                    <Icon
                        name="chevronRight"
                        fill={colors.black87}
                        width={20}
                        height={20}
                        style={
                            active === id
                                ? { transform: "rotate(90deg)" }
                                : { transform: "rotate(0deg)" }
                        }
                    />
                </div>
                <div css={accordionItem.title}>
                    <h4>{title}</h4>
                    <h5>{subtitle}</h5>
                </div>
            </div>
            <div
                ref={contentEl}
                css={[
                    accordionItem.collapse,
                    active === id ? accordionItem.collapseActive : undefined,
                ]}
                style={
                    active === id ? { height: contentEl.current.scrollHeight } : { height: "0px" }
                }
            >
                <div css={accordionItem.content}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    <div css={accordionItem.action}>
                        <ButtonShape component="a" href={buttonLink} target="_blank" fullWidth>
                            {buttonText}
                        </ButtonShape>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ResetForgottenPasswordModal = props => {
    const { showResetPasswordModal, hideResetPasswordModal } = props;
    const [active, setActive] = useState(null);
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const handleToggle = index => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    };

    const handleClose = () => {
        hideResetPasswordModal();
        setActive(null);
    };

    return (
        <ModalBox
            open={showResetPasswordModal}
            onClose={handleClose}
            disableBackdropClick={false}
            maxWidth="md"
            modalWidth={378}
            fullScreen={isMobile}
            title="Forgotten username or&nbsp;password?"
        >
            <ModalBoxText>
                <p>There are two ways you can log in to Student&nbsp;Hub:</p>
                <div css={resetPassModal.accordionList}>
                    {items.map((item, index) => {
                        return (
                            <AccordionItem
                                key={index}
                                active={active}
                                handleToggle={handleToggle}
                                item={item}
                            />
                        );
                    })}
                </div>
                <p css={resetPassModal.hint}>
                    Pro tip: click the eye icon in the password field when logging into Student Hub
                    to make sure you don&apos;t have any typos!
                </p>
                <p>
                    If you don&apos;t know your username you can contact LSE IT support:{" "}
                    <ul css={resetPassModal.list}>
                        <li css={resetPassModal.item}>
                            Email{" - "}
                            <a href="mailto:tech.support@lse.ac.uk" css={resetPassModal.link}>
                                tech.support@lse.ac.uk
                            </a>
                        </li>
                        <li css={resetPassModal.item}>
                            Phone{" - "}
                            <a href="tel:02071075000" css={resetPassModal.link}>
                                0207 107 5000
                            </a>
                        </li>
                        <li css={resetPassModal.item}>
                            Visit the Tech Centre on the first floor of the Library building, Monday
                            to Friday 09:00 - 19:00, Saturday & Sunday 11:00 - 18:00{" "}
                        </li>
                    </ul>
                </p>
            </ModalBoxText>
        </ModalBox>
    );
};

const mapStateToProps = state => ({
    showResetPasswordModal: state.Auth.showResetPasswordModal,
});

const dispatchToProps = {
    hideResetPasswordModal: authActionsCreators.hideResetPasswordModal.create,
};

export default connect(mapStateToProps, dispatchToProps)(ResetForgottenPasswordModal);
